<template>
  <div>
    <v-btn @click="dialog = true" color="primary">Item Types</v-btn>
    <v-dialog v-model="dialog" persistent width="900">
      <v-card>
        <v-card-title>Item Types</v-card-title>
        <v-card-text>
          <div class="d-flex">
            <h3 class="mr-2 green--text mt-5">ADD NEW</h3>
            <v-text-field
              class="mr-2"
              label="Item Type Name"
              v-model="form.item_type"
            ></v-text-field>
            <v-text-field
              label="Description"
              v-model="form.description"
            ></v-text-field>
            <v-btn color="primary" :loading="loading" @click="add">Add</v-btn>
          </div>
          <v-text-field
            label="Search"
            prepend-icon="mdi-magnify"
            v-model="search"
          ></v-text-field>
          <v-data-table
            :search="search"
            :items="item_types"
            :headers="item_type_headers"
          >
            <template v-slot:item.action="{ item }">
              <edit-item-type-modal :item="item" />
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">itemTypesModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import editItemTypeModal from "./editItemTypeModal.vue";

export default {
  components: { editItemTypeModal },
  data() {
    return {
      search: "",
      form: {},

      item_type_headers: [
        { text: "Action", value: "action" },
        { text: "ID", value: "id" },
        { text: "Item Type", value: "item_type" },
        { text: "Description", value: "description", width: "400" },
        { text: "Associated Items", value: "items_total", width: "150" },
      ],
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      item_types: "item_type/item_types",
    }),
  },
  methods: {
    ...mapActions({
      add_item_type: "item_type/add_item_type",
      get_item_type: "item_type/get_item_types",
    }),
    submit() {
      return;
    },
    async add() {
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        this.loading = true;
        this.add_item_type(this.form).then(() => {
          alert("Successfully added a new item type");
        });
        this.loading = false;
      }
    },
  },
  async created() {},
};
</script>

<style lang="scss" scoped></style>
