<template>
  <v-container class="mt-16">
    <div class="mb-2 d-flex">
      <p class="subtitle-2">Actions:</p>
      <print-batch-item-modal />

      <returned-items-modal class="ml-2" />
      <all-item-modal />
      <item-types-modal class="mr-2" />
      <v-btn color="yellow" @click="print_inventory">Print Inventory</v-btn>
    </div>
    <v-divider></v-divider>
    <div v-if="ready">
      <!-- ITEMS TABLE  -->
      <v-card class="pl-5 pr-5" flat>
        <v-card-title class="d-flex">
          <h2 class="text-uppercase">Products and Services</h2>
          <v-spacer></v-spacer>
          <AddItemModal />
          <v-btn
            color=""
            class=""
            icon
            x-large
            @click="refresh"
            :loading="loading"
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="item_search"
            prepend-icon="mdi-magnify"
            label="Search Item"
          ></v-text-field>
          <v-data-table
            :items="items"
            :headers="item_headers"
            :search="item_search"
          >
            <template v-slot:item.item_name="{ item }">
              <span v-if="item.is_vat" class="green--text font-weight-bold">{{
                item.item_name
              }}</span>
              <span v-else class="orange--text font-weight-bold">{{
                item.item_name
              }}</span>
            </template>
            <template v-slot:item.warranty="{ item }">
              <span v-if="item.warranty">{{
                item.warranty.warranty_count +
                " " +
                item.warranty.warranty_duration
              }}</span>
            </template>

            <template v-slot:item.action="{ item }">
              <div class="d-flex">
                <edit-item-modal :item="item" />
                <edit-resellers-item-modal :item="item" />

                <v-btn
                  icon
                  color="red"
                  @click="confirm_delete(item)"
                  :loading="loading"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </div>
            </template>
          </v-data-table></v-card-text
        >
      </v-card>
      <v-divider></v-divider>
    </div>

    <v-row v-else class="d-flex justify-center">
      <h2 class="text-center mt-10 mb-10">Data Table is loading...</h2>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import AddItemModal from "../components/Item/addItemModal.vue";
// import AddStockModal from "../components/Item/addStockModal.vue";
import AllItemModal from "../components/Item/allItemModal.vue";
import EditItemModal from "../components/Item/editItemModal.vue";
import EditResellersItemModal from "../components/Item/editResellersItemModal.vue";
import ItemTypesModal from "../components/Item/itemTypesModal.vue";
import PrintBatchItemModal from "../components/Item/printBatchItemModal.vue";
// import PrintSalesSummaryModal from "../components/Item/printSalesSummaryModal.vue";
import ReturnedItemsModal from "../components/Item/returnedItemsModal.vue";

export default {
  data() {
    return {
      item_search: "",
      service_search: "",
      loading: false,
      ready: false,

      item_headers: [
        { text: "Actions", value: "action" },
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Item Name",
          value: "item_name",
        },
        {
          text: "Import Batch Description",
          value: "import_batch.batch_description",
        },
        {
          text: "Supplier",
          value: "supplier.supplier_name",
        },
        {
          text: "Unit Price",
          value: "unit_price",
        },
        {
          text: "Shipping Cost",
          value: "shipping_cost",
        },
        {
          text: "Item Type",
          value: "item_type.item_type",
        },
        {
          text: "Selling Price",
          value: "selling_price",
        },
        {
          text: "Available Stocks",
          value: "quantity",
        },
        {
          text: "Initial Import",
          value: "initial_import",
        },
        {
          text: "Account Title",
          value: "chart_of_account.account_title",
        },
        {
          text: "Unit",
          value: "unit",
        },
        {
          text: "Warranty",
          value: "warranty",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      items: "item/items",
      default_server_url: "auth/default_server_url",
      item_types: "item_type/item_types",
    }),
  },
  methods: {
    print_inventory() {
      let display_price = window.confirm("Display price?");
      display_price = display_price ? 1 : 0;
      let url =
        this.default_server_url +
        "/print_inventory/" +
        this.user.branch_id +
        "/" +
        display_price;
      window.open(url);
    },
    del(service) {
      let x = window.confirm("Are you sure you want to delete this service?");
      this.loading = true;
      if (x) {
        this.delete_service(service.id).then(() => {
          alert("Successfully deleted a service");
        });
      }
      this.loading = false;
    },
    confirm_delete(item) {
      let x = window.confirm("Are you sure you want to delete this item?");
      if (x) {
        this.loading = true;
        this.delete_item(item.id).then(() => {
          alert("Successfully deleted an item");
          this.loading = false;
        });
      }
    },
    ...mapActions({
      get_items: "item/get_items",
      delete_item: "item/delete_item",
      delete_service: "service/delete_service",
      get_item_types: "item_type/get_item_types",
    }),
    refresh() {
      this.loading = true;

      this.get_items().then(() => {
        this.loading = false;
      });
    },
    edit_service(item) {
      console.log(item);
    },
  },
  async created() {
    if (this.items.length <= 0) await this.get_items();
    if (this.item_types.length <= 0) await this.get_item_types();
    this.ready = true;
  },
  components: {
    AddItemModal,
    EditItemModal,
    // AddStockModal,
    PrintBatchItemModal,
    // PrintSalesSummaryModal,
    ReturnedItemsModal,
    AllItemModal,
    EditResellersItemModal,
    ItemTypesModal,
  },
};
</script>

<style
  lang="scss,
    PrintSalesSummaryModal"
  scoped
></style>
