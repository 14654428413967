<template>
  <div>
    <v-btn @click="dialog = true" small text
      ><v-icon class="mr-2">mdi-box</v-icon>All Items List</v-btn
    >
    <v-dialog v-model="dialog" persistent width="1400">
      <v-card>
        <v-card-title>All Items</v-card-title>
        <v-card-text>
          <v-text-field v-model="search"></v-text-field>
          <v-data-table
            :items="all_items"
            :headers="all_items_headers"
            :search="search"
          >
            <template v-slot:item.is_deleted="{ item }">
              <v-btn
                text
                :color="item.is_deleted === 1 ? 'red' : 'primary'"
                small
                class="white--text"
                ><span v-if="item.is_deleted === 1">Deleted</span
                ><span v-else>Active</span></v-btn
              >
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn color="success" @click="restore(item)" small
                ><v-icon>mdi-refresh</v-icon>Restore</v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      search: "",
      dialog: false,
      loading: false,
      all_items_headers: [
        { text: "ID", value: "id" },
        { text: "Item Name", value: "item_name" },
        { text: "Unit Price", value: "unit_price" },
        { text: "Shipping Cost", value: "shipping_cost" },
        { text: "Selling Price", value: "selling_price" },
        { text: "Supplier", value: "supplier.supplier_name" },
        {
          text: "Batch import",
          value: "import_batch.batch_description",
        },
        { text: "Date Arrived", value: "import_batch.date_arrived" },
        { text: "Status", value: "is_deleted" },
        { text: "Action", value: "action" },
      ],
    };
  },
  methods: {
    submit() {},
    restore(item) {
      let x = window.confirm("Are you sure you want to restore this item?");
      if (x) {
        this.restore_item(item).then(() => {
          alert("restored");
        });
      }
    },
    ...mapActions({
      restore_item: "item/restore_item",
    }),
  },
  computed: {
    ...mapGetters({
      all_items: "item/all_items",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
