<template>
  <div>
    <v-btn @click="dialog = true" icon color="yellow"
      ><v-icon>mdi-pencil</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Edit Iem</v-card-title>
        <v-card-text>
          <v-select
            label="Chart of Account"
            :items="chart_of_accounts"
            item-text="account_title"
            item-value="id"
            v-model="item.chart_of_account_id"
          ></v-select>
          <v-text-field
            v-model="item.item_name"
            label="Item Name"
          ></v-text-field>

          <v-select
            v-model="item.item_type_id"
            label="Item Type"
            :items="item_types"
            item-text="item_type"
            item-value="id"
          ></v-select>
          <v-select
            v-model="item.supplier_id"
            label="Supplier"
            :items="suppliers"
            item-text="supplier_name"
            item-value="id"
          ></v-select>
          <v-text-field
            label="Unit Price"
            v-model="item.unit_price"
          ></v-text-field>
          <v-text-field
            label="Shipping Cost"
            v-model="item.shipping_cost"
          ></v-text-field>
          <v-text-field
            label="Selling Price"
            v-model="item.selling_price"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-package-variant-closed-plus"
            type="number"
            label="Initial Import"
            v-model="item.initial_import"
          ></v-text-field>
          <v-text-field
            type="number"
            label="Quantity"
            v-model="item.quantity"
          ></v-text-field>
          <v-text-field label="Unit" v-model="item.unit"></v-text-field>
          <h4>Warranty</h4>
          <div class="d-flex">
            <v-text-field
              label="Warranty Count"
              v-model="item.warranty.warranty_count"
            ></v-text-field>
            <v-select
              v-model="item.warranty.warranty_duration"
              label="Warranty Duration"
              :items="['Day/s', 'Week/s', 'Months/s', 'Year']"
            ></v-select>
          </div>
          <v-select
            v-model="item.import_batch_id"
            label="Batch Import Description"
            :items="import_batches"
            item-value="id"
            item-text="batch_description"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading">Edit</v-btn>
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters({
      item_types: "item_type/item_types",
      suppliers: "supplier/suppliers",
      import_batches: "importBatch/import_batches",
      chart_of_accounts: "chart_of_account/chart_of_accounts",
    }),
  },
  methods: {
    ...mapActions({
      edit_item: "item/edit_item",
    }),
    submit() {
      let x = window.confirm("Are you sure you want to edit this item?");
      if (x) {
        this.loading = true;
        this.edit_item(this.item).then(() => {
          alert("Successfully edited an item!");
          this.loading = false;
        });
      }
    },
    open() {
      console.log("clicked");
      alert("hey");
    },
  },
  props: ["item"],
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
